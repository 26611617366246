'use strict';

class ClientsCtrl {
  constructor($rootScope, $sce) {
    this.welcome = {
      heading: $sce.trustAsHtml('We make it happen: Project&nbsp;42'),
      message: 'Our mission is to work with your team to discover your process needs, to create a customised software solution, using the latest knowledge and technology, and to inspire a better way of doing business.'
    };

    this.clients = [{
      logo: '/assets/jpg/ivs-logo-labs.jpg',
      name: 'IVS Labs',
      full: 'Independent Verification Services Laboratory',
      link: 'http://ivs.nz',
      contact: {
        name: 'Colin Malcolm',
        title: 'Laboratory Manager',
        img: '/assets/clients/colin_malcolm.jpg'
      },
      quote: '"Project 42 have been engaged to develop a comprehensive new Laboratory Information Management System (LIMS) for IVS Laboratory. We have been very impressed with the complex and up to date programming required and responsiveness of Project 42 staff to regular request from the Laboratory staff during the testing phase. The LIMS system developed will enable new tests procedures to be easily incorporated into the laboratory operation and is fully integrated into our on-line portal database. We have been very happy with the service and development provided by Project 42."',
    }, {
      logo: '/assets/png/ivs-logo-inspection.png',
      name: 'IVS',
      full: 'Independent Verification Services Inspection',
      link: 'http://ivs.nz',
      contact: {
        name: 'Brad Miller',
        title: 'Inspections Manager',
        img: '/assets/clients/brad_miller.jpg'
      },
      quote: '"Based on our experience I would not hesitate to recommend the services of Project 42 to others looking for professional software development. The collaborative relationship worked really well during the planning and design and we now have a program that works well for us and our clients. No task is too big or small; they are reliable, and work with a great sense of urgency."',
    }, {
      logo: '/assets/png/jumpflex_logo.png',
      name: 'JumpFlex Trampolines',
      full: '',
      link: '',
      contact: {
        name: 'Stephen Tubbs',
        title: 'CEO of JumpFlex',
        img: '/assets/png/jumpflex_logo.png'
      },
      quote: '"I needed a seamless integration between our back-end inventory system and our delivery partner, Project 42 were more than capable at setting this set this up for us. Project 42 delivered a modern, cost effective solution and were great to deal with."'
    }];

    this.activeClient = this.clients[0];

    this.setActiveClient = this.setActiveClient;
  }

  setActiveClient (client) {
    this.activeClient = client;
  }

  isActiveClient (client) {
    return this.activeClient === client;
  }
}

(function(app) {
  app.controller('ClientsCtrl', ClientsCtrl);
}(angular.module('Project42.Homepage')));
