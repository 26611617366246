'use strict';

class PortfolioCtrl {
  constructor($scope, $sce) {
    $scope.carouselIndex = 0;

    $scope.slides = [
      {
        up: true,
        heading: 'LIMS - Lab Information System',
        description: $sce.trustAsHtml('<b>Problem:</b> Client was looking for a software program that allowed them to process their test data and generate client reports.  Validations and calculations along with reporting needed to fit with their product and client’s expectations.  System flexibility and accuracy were priority plus allowing their system to be mostly managed in house, along with complying with ISO 17025 and IANZ Accreditation.<br><br><b>Solution:</b>  The ION Laboratory Information Management System (LIMS) was developed to support day to day practise including validations and checks, reporting and Application Programming Interface (API) with external agents reducing double handling, time and costs.'),
          img_url: '/assets/png/ion-labtest.png',
          logo_url: '/assets/jpg/ivs-logo-labs.jpg',
          logo_link: 'http://ivslimited.co.nz/ivs-labs',
          logo_mobile_only: true
      },
      {
        heading: 'Centralised Calendar',
        description: $sce.trustAsHtml('<b>Problem:</b> Customer required country wide visibility of tasks which are allocated across different services and operations.<br><br><b>Solution:</b> A centralised calendar connecting different systems, providing visibility of all tasks, allocated by location, contractor and dates.'),
        img_url: '/assets/jpg/calendar-screenshot.jpg',
        logo_url: '/assets/jpg/ivs-logo-text.jpg',
        logo_link: 'http://ivslimited.co.nz/'
      },
      {
        heading: 'MTF System',
        description: $sce.trustAsHtml('<b>Problem:</b> A software package required to cover national service, allowing for results to be loaded immediately and a facility to store photos and documents.<br><br><b>Solution:</b> A customised software program that allows the company administrator to manage users, customer access and reporting was developed across all devices. This program met all required targets and put the day to day running in the hands of the company.'),
        img_url: '/assets/jpg/facility-screenshot.jpg',
        logo_url: '/assets/jpg/ivs-logo-text.jpg',
        logo_link: 'http://ivslimited.co.nz/'
      }
    ];
  }
}

(function(app) {
  app.controller('PortfolioCtrl', PortfolioCtrl);
}(angular.module('Project42.Homepage')));
