'use strict';

(function (app) {
  app.directive('servicesCard', ['$animate', '$rootScope', function ($animate, $rootScope) {
    return {
      restrict: 'E',
      controller: 'ServicesCardCtrl',
      templateUrl: '/views/homepage/directives/services-card/services-card.html',
      scope: {
        card: '='
      },
      link: function (scope, element, attr) {
        element.on('mouseover', function () {
          element.addClass('active');
          $rootScope.$emit('serviceCardChanged', scope.card);
        });
        // Remove hover animation if new clientCard is not self
        $rootScope.$on('serviceCardChanged', function (e, card) {
          if (card == scope.card) return;
          $rootScope.selectedCard = card;
          element.removeClass('active');
        });
      }
    };
  }]);
}(angular.module('Project42.Homepage')));