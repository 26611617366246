'use strict';

(function (app) {
  app.config(function ($stateProvider) {
    $stateProvider.state('home', {
        url: '/',
        templateUrl: '/views/homepage/homepage.html'
      });
    $stateProvider.state('contact-us', {
      url: '/contact',
      controller: 'ContactUsCtrl',
      controllerAs: 'contactUsCtrl',
      bindToController: true,
      templateUrl: '/views/homepage/contact.html'
    });
  });
}(angular.module('Project42.Homepage')));
