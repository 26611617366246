'use strict';

class StatementCtrl {
  constructor($rootScope, $scope, $interval, $timeout) {
    $scope.title = 'Your answer to IT';

    $scope.slogans = [
      'We make IT happen.',
      'A bit of Kiwi ingenuity,',
      'combined with the latest technology,',
      'creates the IT solution best suited for your business.'
    ];
  }
}

(function(app) {
  app.controller('StatementCtrl', StatementCtrl);
}(angular.module('Project42.Homepage')));