'use strict';

(function (app) {
  app.directive('nav', function () {
    return {
      restrict: 'E',
      controller: 'NavCtrl',
      controllerAs: 'nav',
      templateUrl: '/directives/nav/nav.html'
    };
  });
}(angular.module('Project42')));