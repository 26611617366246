'use strict';

class ServiceCardCtrl {
  constructor($scope, $rootScope, $element) {
    this.$scope = $scope;
    this.$element = $element;
  }
}

(function(app) {
  app.controller('ServicesCardCtrl', ServiceCardCtrl);
}(angular.module('Project42.Homepage')));