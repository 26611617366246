'use strict';

class BucketsCtrl {
  constructor($scope) {
    $scope.buckets = [
      {
        img_src: '/assets/png/lightbulb.png',
        img_alt: 'White lightbulb inside a mellow yellow circle',
        title: 'It starts with an idea',
        info: 'A great product starts with a great idea. Let us help you find a business system solution that will deliver.',
        content: [
          'Have you outgrown your current software application setup?',
          'Or perhaps it\'s a generic product forcing you to change how you operate?',
          'Let our team come up with a new direction that will streamline and future proof how you do business.'
        ],
        a_href: '#services',
        icon_open: 'add',
        text_open: 'More',
        icon_close: 'remove',
        text_close: 'Less'
      }, {
        img_src: '/assets/png/speech_bubble.png',
        img_alt: 'White speech-bubble inside a mellow yellow circle',
        title: 'Then we discuss your needs',
        info: 'Enjoy a coffee with us and discuss all aspects of your current system setup and requirements.',
        content: [
          'Bring your idea’s to us and we can work together to plan out a system that captures all the needs of the process and business delivery.',
          'Our small team work on the principle of providing you with the tools to manage software simply yourself by developing a software package to allow growth and change.'
        ],
        a_href: '#clients',
        icon_open: 'add',
        text_open: 'More',
        icon_close: 'remove',
        text_close: 'Less'
      }, {
        img_src: '/assets/png/whiteboard.png',
        img_alt: 'White chalkboard inside a mellow yellow circle',
        title: 'A full plan is created',
        info: 'Our team gets to work to implement a project plan that ensures we create a great solution for you.',
        content: [
          'We will make sure you know all the options that are available to you, off-the-shelf software or purpose built – you decide.'
        ],
        a_href: '#services',
        icon_open: 'add',
        text_open: 'More',
        icon_close: 'remove',
        text_close: 'Less'
      }, {
        img_src: '/assets/png/tick.png',
        img_alt: 'White tick inside a mellow yellow circle',
        content: [
          'Like any partnership we are here to support you going forward.'
        ],
        title: 'A great solution is delivered',
        info: 'Using our knowledge and expertise, we deliver a platform that exceeds your expectation.',
        a_href: '#services',
        icon_open: 'add',
        text_open: 'More',
        icon_close: 'remove',
        text_close: 'Less'
      }
    ];
  }
}

(function(app) {
  app.controller('BucketsCtrl', BucketsCtrl);
}(angular.module('Project42.Homepage')));