'use strict';

class BucketCtrl {
  constructor($scope, $element, $mdMedia) {
    this.$scope = $scope;
    this.$element = $element;

    $scope.toggle = false;
    $scope.$mdMedia = $mdMedia;
  }
}

(function (app) {
  app.controller('BucketCtrl', BucketCtrl);
}(angular.module('Project42.Homepage')));