'use strict';

angular.module('Project42.Homepage', [ 'ngMaterial', 'ui.router' ]);

class Project42Ctrl {
  constructor ($rootScope, $anchorScroll, $state, $location, $window, $timeout) {
    this.$rootScope = $rootScope;
    this.$state     = $state;

    $rootScope.state = function (state, id = null) {
      $state.go(state)
        .then(
          function () {
            $timeout(function () {
              if (id) {
                $location.hash(id);
              }
              $anchorScroll();
            });
          },
          function (err) {
            console.error(err);
          }
        );
    };
    $rootScope.path = function (path) {
      $location.path(path);
    };
  }

  getTitle() {
    let name = '';
    switch (this.$state.current.name) {
      case 'home':
        name = ' - Home';
        break;
      case 'contact-us':
        name = ' - Contact Us';
        break;
    }
    return 'Project 42' + name;
  }
}

(function (app) {

  app.controller('Project42Ctrl', Project42Ctrl);

  app.config(function ($locationProvider) {
    $locationProvider.hashPrefix('!');
    $locationProvider.html5Mode({
      enabled:      true,
      requireBase:  false
    });
  });

  app.config(function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/404');
    $stateProvider
      .state('404', {
        url: '/404',
        templateUrl: '/404.html'
      });
  });

  app.run(function () {
    console.log('Project42 says Hello, World!');
  });

  app.run(function ($rootScope) {
    $rootScope.$on('$stateChangeError', console.log.bind(console));
  });

  app.run(function ($rootScope, $window, $location) {
    $rootScope
      .$on('$stateChangeSuccess',
          function(event){
            if (!$window.ga)
                return;

            $window.ga('send', 'pageview', { page: $location.path() });
      });
  });

  app.run(function ($anchorScroll) {
    $anchorScroll.yOffset = 82;
  });

}(angular.module('Project42', [
  'ngAnimate',
  'Project42.Homepage',
  'angular-flexslider'
])));
