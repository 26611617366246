'use strict';

class ServiceDescriptionCtrl {
  constructor($scope) {
    this.$scope = $scope;
  }
}

(function(app) {
  app.controller('ServiceDescriptionCtrl', ServiceDescriptionCtrl);
}(angular.module('Project42.Homepage')));