'use strict';

class AppMenuCtrl {
  constructor($scope, $element, $mdSidenav) {
    this.$scope = $scope;
    this.$mdSidenav = $mdSidenav;

    $scope.$watch(
      function watchValue () {
        return $element.find('md-backdrop').length > 0;
      },
      function valueChanged(open) {
        $element.toggleClass('open', open);
      }
    );

    $scope.toggle = this.toggle.bind(this);
    $scope.close = this.close.bind(this);
  }

  toggle() {
    this.$mdSidenav('menu').toggle();
  }

  close() {
    this.$mdSidenav('menu').close();
  }
}

(function (app) {
  app.controller('AppMenuCtrl', AppMenuCtrl);
}(angular.module('Project42')));