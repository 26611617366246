'use strict';

class ClientCardCtrl {
  constructor($scope, $rootScope, $element) {
    this.$scope = $scope;
    this.$element = $element;
  }
}

(function (app) {
  app.controller('ClientCardCtrl', ClientCardCtrl);
}(angular.module('Project42.Homepage')));