'use strict';

(function (app) {
  app.directive('bucket', function () {
    return {
      restrict: 'E',
      scope: {
        bucket: '='
      },
      templateUrl: '/views/homepage/directives/bucket/bucket.html',
      controller: 'BucketCtrl'
    };
  });
}(angular.module('Project42.Homepage')));