'use strict';

(function (app) {
	app.directive('serviceDescription', ['$animate', '$rootScope', function ($animate, $rootScope) {
		return {
			restrict: 'E',
			controller: 'ServiceDescriptionCtrl',
			templateUrl: '/views/homepage/directives/service-description/service-description.html',
			scope: {
        service: '='
      },
      link: function (scope, element, attr) {
        $rootScope.$on('serviceCardChanged', function (e, card) {
          if (card.name == scope.service.name)
            element.addClass('active');
          else {
            element.removeClass('active');
          }
        });
      }
		};
	}]);
}(angular.module('Project42.Homepage')));