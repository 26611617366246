'use strict';

class NavCtrl {
  constructor($scope, $state, $element, $window) {
    this.$scope   = $scope;
    this.$element = $element;
    this.$state   = $state;
    this.$window  = $window;

    // this.$element.toggleClass('scroll', true);

    this.active = false;
  }

  toggleHamburgerMenu () {
    console.log('run');
    this.active = !this.active;
  }

  _updateScrollY (scrollY) {
    // this.$element.toggleClass('scroll', (scrollY > 0) || this.$state.current.name === 'Contact Us');
  }
}

(function (app) {
  app.controller('NavCtrl', NavCtrl);
}(angular.module('Project42.Homepage')));