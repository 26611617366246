'use strict';

class ServicesCtrl {
  constructor ($rootScope, $scope) {
    $scope.softwareService = {
      name: 'Software',
      extended_name: 'Customized Software',
      content: 'How do you work? What\'s your process? Let\'s build a system to capture that while enabling invoicing and reporting.',
      icon: 'exchange',
      description: 'Our team will work with you to develop a solution that delivers on your ideas and expectations. We will create a customised software integrated system that encapsulates end-to-end deliverables. We will be supporting the development with our experience to ensure the functionality and flexibility of the software grows with your business needs.',
      image_path: '/assets/png/software.png'
    };

    $scope.integrationService = {
      name: 'Integration',
      extended_name: 'System Integration',
      content: 'Worried about getting hacked? We will handle security of your system including vulnerability testing and security certificates.',
      icon: 'lock',
      description: 'Working with you, we develop an application that allows your existing systems to share data with ease. Whatever your requirements, full integration with your accounting systems, external transport company or any other third party suite, can securely become a part of your software system. This occurs seamlessly and without interruption to your current business process.',
      image_path: '/assets/png/integration.png'
    };

    $scope.selectedCard = $rootScope.selectedCard;
  }
}

(function(app) {
  app.controller('ServicesCtrl', ServicesCtrl);
}(angular.module('Project42.Homepage')));