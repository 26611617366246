'use strict';

class ContactUsCtrl {
  constructor($scope) {
  this.$scope = $scope;
  }
}

(function(app) {
  app.controller('ContactUsCtrl', ['$scope', '$http', function($scope, $http) {
    $scope.name = '';
    $scope.email = '';
    $scope.message = '';
    $scope.contact_number = '';

    $scope.spinner = false;
    $scope.message_sent = false;

    $scope.submit = function () {
      $scope.spinner = true;

      $http({
        method: 'POST',
        url: '/mailer',
        data: {
          name: $scope.name,
          email: $scope.email,
          contact_number: $scope.contact_number,
          subject: $scope.subject,
          message: $scope.message
        }
      }).then(function success(response) {
        $scope.spinner = false;
        $scope.message_sent = true;
      }, function error(response) {
        $scope.spinner = false;
      });
    };
  }]);
}(angular.module('Project42.Homepage'))); 