'use strict';

(function (app) {
  app.directive('clientCard', ['$animate', '$rootScope', function ($animate, $rootScope) {
    return {
      restrict: 'E',
      controller: 'ClientCardCtrl',
      templateUrl: '/views/homepage/directives/client-card/client-card.html',
      scope: {
        client: '='
      },
      link: function (scope, element, attrs) {
        // Add hover animation on mouseover event
        element.on('click', function () {
          element.addClass('active');
          $rootScope.$emit('clientCardChanged', scope.client);
        });
        // Remove hover animation if new clientCard is not self
        $rootScope.$on('clientCardChanged', function (e, client) {
          if (client == scope.client) return;
          element.removeClass('active');
        });
      }
    };
  }]);
}(angular.module('Project42.Homepage')));