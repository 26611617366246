'use strict';

(function (app) {
  app.directive('appMenu', function () {
    return {
      restrict: 'E',
      controller: 'AppMenuCtrl',
      templateUrl: '/directives/app-menu/app-menu.html'
    };
  });
}(angular.module('Project42')));